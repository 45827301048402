import React, { Component } from 'react';
import Branch from '../components/Branch';
import BranchDirectRow from '../components/BranchDirectRow';
import BranchDirectColumn from '../components/BranchDirectColumn';
import Leaf from '../components/Leaf';
import Card from '../components/Card';

//Images
import connectPhone from '../assets/img/connect-phone.jpg';
import uiux from '../assets/img/uiux.jpg';
import developmentWeb from '../assets/img/development-web.jpg';

//Video
import AnimationVid from '../assets/video/powerOf2.mp4';
import AnimationPoster from '../assets/video/poster-powerOf2.png';
import GamdevVid from '../assets/video/kendel.mp4';
import GamedevPoster from '../assets/video/poster-kendal.png';
import lightVid from '../assets/video/video-light.mp4';
import lightPoster from '../assets/video/poster-light.png';

export default class About extends Component {
 
  render() { 
      return (
              <>
      <BranchDirectColumn>
          <BranchDirectRow>
          <Branch>
              <div className="leafnopad">
                <div className="introduction">
                <h1>
                  About The Green Branch
                </h1>
                <p>
                As Game Designer, Webdesigner and Web developer I am known to workflows of each field of work
                and their challenges.<br/>
                Therefore combining the
                knowledge of those to craft solutions for the digital realm is what The Green Branch does.
                </p>
                </div>
                </div>
              </Branch>
          </BranchDirectRow>
          <BranchDirectRow>
            <Branch>  
            <Leaf>
              <Card
                mainClass='vein animated'
                cardImg={connectPhone}
                cardVid=''
                headline="Design"
                subline="Solutions for digital content and devices"
                content=" Design can be applied to everything and therefore 
                          can be used for promotional content, websites, apps, games, ... or documents and graphics."
                button='More'
                btnActive='disabled'
                link="/about"
              />  
            </Leaf> 
            <Leaf>
              <Card
                mainClass='vein animated'
                cardVid=''
                cardImg={uiux}
                headline="UI & UX"
                subline="The Bridge to Mt. Software"
                content="To connect the User to the software the Interface needs to emulate the 
                          behavior of their real-life counterpart and guide with feedback and information."
                button='Tell your Tale now'
                btnActive='disabled'
                link="https://serene-badlands-47207.herokuapp.com/"
                linkTarget="_blank"
              />   
            </Leaf> 
            <Leaf>
            <Card
                mainClass='vein animated'
                cardVid=''
                cardImg={developmentWeb}
                headline="Web Development"
                subline="Communicating front- to backend and vice versa"
                content="If Server or Cloud Node.js is a handy solution to connect to a React.js 
                          frontend. The integration of a CMS enhances the capability of a dynamic website / app."
                button='More about the Ironlight'
                btnActive='disabled'
                link="/ironlight"
              />  
            </Leaf> 

          </Branch> 
          </BranchDirectRow>
          <BranchDirectRow>
            <Branch>  
            <Leaf>
              <Card
                mainClass='vein animated'
                cardVid={GamdevVid}
                cardImg={GamedevPoster}
                headline="Game Development"
                subline="Portals to other Worlds"
                content="The Unreal engine is a great tool to develop 
                          new worlds and craft stories for people to enjoy. Incident [526] is currently in development."
                button='Tell your Tale now'
                btnActive='disabled'
                link="https://serene-badlands-47207.herokuapp.com/"
                linkTarget="_blank"
              />   
            </Leaf> 
            <Leaf>
              <Card
                mainClass='vein animated'
                cardVid={AnimationVid}
                controls='1'
                cardImg={AnimationPoster}
                headline="Animation"
                subline="Life to images and emotions to elements"
                content="There are several ways to use animations for websites / apps with CSS, SVG, JavaScript, prebuild as  
                          Video or for elements to enhance the UI / UX."
                button='More'
                btnActive='disabled'
                link="/about"
              />  
            </Leaf> 
            <Leaf>
            <Card
                mainClass='vein animated'
                cardVid={lightVid}
                controls='1'
                cardImg={lightPoster}
                headline="Video Editing"
                subline="Highlight your story"
                content="Videos can be edited in various ways depending on the premise a different route is to take. 
                          The result should be to convey a message, feeling, a thought or story."
                button='More about the Ironlight'
                btnActive='disabled'
                link="/ironlight"
              />  
            </Leaf> 
          
          </Branch> 
          </BranchDirectRow>
      </BranchDirectColumn>
              </>
              );
           }
}