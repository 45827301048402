document.addEventListener("DOMContentLoaded", ()=>{
  const parallax = document.getElementsByClassName('parallax');
  const leafnopad = document.getElementsByClassName('leafnopad');
  Object.keys(parallax).map(function(key, index) {
  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;
  const parallaxHeight = parallax[key].offsetHeight;
  const leafnopadHeight = leafnopad[0].offsetHeight;

  parallax[0].style.backgroundPositionY = leafnopadHeight+100 + 'px';
  parallax[key].style.backgroundSize = windowWidth + 'px';
  parallax[key].style.height = windowWidth - leafnopadHeight  + 'px';
  });
}); 

window.addEventListener("resize", ()=>{
  const parallax = document.getElementsByClassName('parallax');
  const leafnopad = document.getElementsByClassName('leafnopad');
  Object.keys(parallax).map(function(key, index) {
  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;
  const parallaxHeight = parallax[key].offsetHeight;
  const leafnopadHeight = leafnopad[0].offsetHeight;

  parallax[0].style.backgroundPositionY = leafnopadHeight+100 + 'px';
  parallax[key].style.backgroundSize = windowWidth + 'px';
  parallax[key].style.height = windowWidth - leafnopadHeight  + 'px';
  });
}); 
