document.addEventListener("DOMContentLoaded", ()=>{
    // temp script part
     const barkLeft = document.getElementsByClassName('bark-left ')
     const barkTop = document.getElementsByClassName('bark-top ')
     const mrms = document.getElementsByClassName('mrms')
     const h1 = document.querySelectorAll('h1')
     if(mrms.length >= 0){
       barkLeft[0].classList.add('disabled');
       barkTop[0].classList.add('disabled');
       h1[0].classList.add('h1color');
       console.log(mrms.length)
     }
});
