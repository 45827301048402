import React, { Component } from 'react';


export default class CardContentReg extends Component {
  constructor(props) {    
    super(props)
    this.state = {

      };

}


  
    render() { 
        return (
                <ul>
                    <li>
                        <h3>Citizen</h3>
                        We understand you need to earn a living, therefore fewer activity points won't excommunicate you from the Ironlight.<br/>
                        <h4>Silver: 0.000 </h4>
                    </li>
                    <li> 
                        <h3>Soldier</h3> 
                        As Soldier, we honor your support and welcome you to shine bright with the Ironlight.<br/>
                        <h4>Silver: 30k - 1m</h4>
                    </li>
                    <li>
                        <h3>Officer</h3>
                        A one million Contract grants you permission to put forward a request to join the Officer Hall.<br/>
                        <h4>Silver: 1m - 10m</h4>
                    </li>
                </ul>
                );
             }
}