import React from 'react';

import Branch from '../components/Branch';

export const Page404 = () => {
    return (
        <>
        <Branch>
            <h1>404</h1>
        </Branch>
        </>
    )

}