import React from 'react';
import './App.scss';
import { Switch, Route } from 'react-router-dom'
import BarkTop from './components/BarkTop';
import Root from './components/Root';
import BarkLeft from './components/BarkLeft';

import About from './pages/About';
import Ironlight from './pages/Ironlight';
  import Story from './pages/Story';
  import Sanctuary from './pages/Sanctuary';
  import InnerAffairs from './pages/InnerAffairs';
  import Tournaments from './pages/Tournaments';

import {Page404} from './pages/Page404';
import { Dashboard } from './pages/Dashboard';

//not Linked in UI
import MrMatheSchmitt from './pages/MrMatheSchmitt';
import impressumMrms from './pages/impressumMrms';
import agbMrms from './pages/agbMrms';
function App() {

  return (
    <div className="App">
      <BarkTop/>
      <Root>
        <BarkLeft/>
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/about" component={About} />

          <Route exact path="/ironlight" component={Ironlight} />
            <Route exact path="/ironlight/Story" component={Story} />
            <Route exact path="/ironlight/Sanctuary" component={Sanctuary} />
            <Route exact path="/ironlight/inneraffairs" component={InnerAffairs} />
            <Route exact path="/ironlight/tournaments" component={Tournaments} />
            
          <Route exact path="/mrmatheschmitt" component={MrMatheSchmitt} />
          <Route exact path="/mrmatheschmitt/impressum" component={impressumMrms} />
          <Route exact path="/mrmatheschmitt/agb" component={agbMrms} />
          <Route path="*" component={Page404} />
        </Switch>
      </Root>
    </div>
  );
}

export default App;
