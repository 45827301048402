import React from 'react';
import Branch from '../components/Branch';
import BranchDirectRow from '../components/BranchDirectRow';
import BranchDirectColumn from '../components/BranchDirectColumn';
import Leaf from '../components/Leaf';
import Card from '../components/Card';
import teaserIronlight from '../assets/img/bgr_0.jpg';
import teaserGreenbranch from '../assets/img/greenbranch.jpg';
import teaserStoryteller from '../assets/img/storyteller.jpg';

export const Dashboard = () => {
    return (
        <>
        <BranchDirectColumn>
        <BranchDirectRow>
          <Branch>
              <div className="leafnopad">
                <div className="introduction">
                <h1>
                  Welcome to The Green Branch
                </h1>
                <p>
                A Design and Development Portfolio. <br/> 
                This Website is work in progress and therefore changes through newly developed content.<br/>
                  <em>All Content of this website is created and owned by The Green Branch</em>
                  <em> ( optimized for Google Chrome )</em> <br/> <br/>
                 
                  © Copyright 2020 The Green Branch<br/>
                  
                </p>
                </div>
                </div>
              </Branch>
          </BranchDirectRow>
          <BranchDirectRow> 
            <Branch>  
            <Leaf>
              <Card
                mainClass='vein'
                cardImg={teaserGreenbranch}
                headline="About The Green Branch"
                subline="Design and Development for Web and Games"
                content=" As Game Designer, Webdesigner and Web developer I am known to workflows of each field and their challenges. Therefore combining 
                          the knowledge of those to craft solutions for the digital realm is what The Green Branch does."
                button='More'
                btnActive='vein-btn'
                link="/about"
              />  
            </Leaf> 
            <Leaf>
            <Card
                mainClass='vein'
                cardImg={teaserIronlight}
                headline="Ironlight"
                subline="The Black Desert Online Guild"
                content="  The Ironloght combines the roleplay mechanics of Pen & Paper and the new possibilities of modern Games with web design/development
                           and marketing to elevate the roleplay part of Massively Multiplayer Online Role-Playing Games."
                button='More about the Ironlight'
                btnActive='vein-btn'
                link="/ironlight"
              />  
            </Leaf> 
            <Leaf>
              <Card
                mainClass='vein'
                cardImg={teaserStoryteller}
                headline="Ironlight Storyteller"
                subline="A Web app with integrated Discord Bot"
                content="The Storyteller is a cloud-based Web app to create narratives base on user-created content made ingame. With the integrated Discord Bot,
                         those Adventures also get posted to the corresponding Discord Channel to further develop the narratives."
                button='Tell your story'
                btnActive='vein-btn'
                link="https://serene-badlands-47207.herokuapp.com/"
                linkTarget="_blank"
              />   
            </Leaf> 
          </Branch> 
          </BranchDirectRow>
        </BranchDirectColumn>
        </>
    )

}