import React, { Component } from 'react';
import Branch from '../components/Branch';
import BranchDirectRow from '../components/BranchDirectRow';
import BranchDirectColumn from '../components/BranchDirectColumn';
import Leaf from '../components/Leaf';
import Card from '../components/Card';
import '../mrms/mrmsApp.scss';

export default class AGB extends Component {
  constructor(props) {    
    super(props);
  
    this.state = {

      };
     
  }

  render() { 
    require('../mrms/mrmsApp.scss')
    require('../functions/disable')
      return (
              <>
  <div className="mrms"> 
      <BranchDirectColumn>  
          <BranchDirectRow>   
          <Branch>
            <div className="leafnopad">
            <div className="introduction">
              <h1>
              AGB
              </h1>
              <h2>
              Urheber- und Leistungsschutzrechte
              </h2>
              <p>
              Die auf dieser Website veröffentlichten Inhalte unterliegen dem deutschen Urheber- und Leistungsschutzrecht. Jede vom deutschen Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen Rechteinhabers. Dies gilt insbesondere für Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen. Die unerlaubte Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht gestattet und strafbar.
              </p>
              </div>
            </div>
          </Branch>
          </BranchDirectRow>

         
       
      </BranchDirectColumn>
      </div>
     
              </>
              );
           }
}