import React, { Component } from 'react';
import '../App.scss';
import Branch from '../components/Branch';
import BranchDirectRow from '../components/BranchDirectRow';
import BranchDirectColumn from '../components/BranchDirectColumn';
import Leaf from '../components/Leaf';
import Card from '../components/Card';

//Images
import ironlight from '../assets/img/Ironlight.jpg';
import crusader from '../assets/img/Crusader.jpg';
import inquisition from '../assets/img/Inquisition.jpg'

//Video


export default class About extends Component {
 
  render() { 
      return (
              <>
      <BranchDirectColumn>
      <BranchDirectRow>
              <Branch>
              <div className="leafnopad">
                <div className="introduction">
                <h1>
                  The Chapels
                </h1>
                <p>
                The Ironloght combines the roleplay mechanics of Pen & Paper and the new possibilities of modern Games<br/> 
                with web design/development and marketing to elevate the roleplay part of Massively Multiplayer Online Role-Playing Games.
                </p>
                </div>
                </div>
              </Branch>
          </BranchDirectRow>
          <BranchDirectRow>
          <Branch>  
            <Leaf>
              <Card
                cardImg={ironlight}
                cardVid=''
                headline="Ironlight "
                subline="Lead for the Wicked"
                content="The Members of this Chapel wield Blazing Light and Deadly Iron. Injecting Light into the Dark Core. A flash and deadly Iron followed by the sound of certain Death. The War Cry of Ironlight. This Chapel defends the rebuilding process with Tactics, Wealth and riches. The Ironlight provides the Headquarters for all current operations among them dwell those who craft weapons of war, traders, builders, and breeders of the finest War Horses."
                 button='View'
                  btnActive='disabled'
              />  
            </Leaf> 
            <Leaf>
            <Card
                cardImg={crusader}
                cardVid=''
                headline="Crusader"
                subline="Justice for the Corrupted"
                content="Only a handful survived the three days of darkness and despair. With the fall of the last old Sanctuary, a new kind of Crusaders emerged from the dust of the fallen empire. They first understood the need to get rid of the new world order which rose out of the Shadows. They fight to defend their loved ones and to purge the world of corruption. Crusaders are kind and obliging towards those who fight for what is good. A common trade is sharing food with others while talking at the campsite together."
                 button='View'
                  btnActive='disabled'
              />  
            </Leaf> 
            <Leaf>
              <Card
                cardImg={inquisition}
                cardVid=''
                headline="Inquisitor"
                subline="Dead men tell no tales"
                content="Silent but deadly. Such are the words that best describe these relentless enforcers. Their deeds are necessary against Evil. Walking the thin line facing the dark whispering: 'You're not God's chosen one'. For corruption spreads through the mouth the Inquisition made it common that dead men tell no tales. The start of someone's downfall isn't the dagger sticking from their back but the words before it. Inquisitors are known for their cartography skills which allows them to roam the lands unseen."
                 button='View'
                  btnActive='disabled'
              />   
            </Leaf> 
          </Branch> 
          </BranchDirectRow>
      </BranchDirectColumn>
              </>
              );
           }
}