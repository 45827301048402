import React, { Component } from 'react';
import logo  from'../logo-gb.svg';
import EmailInput  from'./EmailInput';
import PasswordInput  from'./PasswordInput';
import BarkTop from './BarkTop';




export default class BarkLeft extends Component {

    constructor() {
        super();
        
        this.state = {
              email:'',
              password:'',
              passwordDB:'test',
              authenticated: false,
              BurgerMenuActive: false,
              SideMenuActive:false,
              LogoActive: true 
            };

        }
        toggleBurgerMenu = () => {
          this.setState({
            BurgerMenuActive: !this.state.BurgerMenuActive,
            SideMenuActive: !this.state.SideMenuActive
          })
        }


        LogIn(password){

          if(password === this.state.passwordDB){
            this.setState({
              LogoActive: !this.state.LogoActive
            })

          }  
        }

      changeHandlerEmail = (email) => {
        this.setState({
          [email.target.name]:email.target.value
        })
      }
      changeHandlerPassword = (password) => {
        this.setState({
          [password.target.name]:password.target.value
        })  
      }


      handleSignIn = event => {
        event.preventDefault()
        let email = this.state.email
        let password = this.state.password
        this.setState({
          password: password,
          email: email
        })
        this.LogIn(password)
      }
   

    render() {
      const BurgerMenuActive = this.state.BurgerMenuActive ? 'checked' : '';
      const SideMenuActive = this.state.SideMenuActive ? 'active' : '';
        return (
                <>
                  <div className={`bark-left ${SideMenuActive}`}>
                      <div onClick={this.toggleBurgerMenu} className={`burgerMenu ${BurgerMenuActive}`}>
                          <span className="line"></span>
                          <span className="line"></span>
                          <span className="line"></span>
                      </div>
                      <nav className="content">
                          <div>
                              <a href="/">
                                  <img className="" src={logo} alt={logo}/>
                              </a>
                          </div>
                          <form onSubmit={this.handleSignIn.bind(this)}>
                              <div className="tgb-form">
                                    <h3 className="">Who is the User?</h3>
                                    <label className="tgb-label">Email address</label>
                                    <EmailInput name="email" type="email"
                                      value={this.state.email.value}
                                      onChange={email =>this.changeHandlerEmail(email)}
                                    /> 
                                    <small id="emailHelp" className="">We'll never share your email with anyone.</small>
                              </div>
                              <div className="tgb-form">
                                  <label className="tgb-label">Password</label>
                                  <PasswordInput name="password" type="password"
                                    value={this.state.password}
                                    onChange={password => this.changeHandlerPassword(password)}
                                    /> 
                                  <button  type="submit" className="tgb-btn authorize">Authorize</button>
                              </div>
                              {/*<button onClick={() => this.pusher} className="tgb-btn authorize">pusher</button>*/}
                             
                          </form>
                      </nav>
                  </div>
                </>
                );
             }
}