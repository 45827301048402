import React, { Component } from 'react';
import Branch from '../components/Branch';
import BranchDirectRow from '../components/BranchDirectRow';
import BranchDirectColumn from '../components/BranchDirectColumn';
import Leaf from '../components/Leaf';
import Card from '../components/Card';
import '../mrms/mrmsApp.scss';

export default class About extends Component {
  constructor(props) {    
    super(props);
  
    this.state = {

      };
     
  }

  render() { 
    require('../mrms/mrmsApp.scss')
    require('../functions/disable')
      return (
              <>
  <div className="mrms"> 
      <BranchDirectColumn>  
          <BranchDirectRow>   
          <Branch>
            <div className="leafnopad">
            <div className="introduction">
              <h1>
              Impressum
              </h1>
              <h2>
              Anbieter dieser Internetinhalts ist:
              </h2>
              <p>
                <ul className="list">
                  <li>
                  Christian Schmitt (Kleinunternehmer)
                  </li>
                  <li>
                  Waisenhaustraße 18
                  </li>
                  <li>
                  56068 Koblenz
                  </li>
                </ul>
              </p>
              <p>
                <ul className="list">
                  <li>
                  Email:
                  </li>
                  <li>
                  matheschmitt@web.de
                  </li>
                </ul>
              </p>
              <p>
                <ul className="list">
                  <li>
                  Telefon:
                  </li>
                  <li>
                  017666672237
                  </li>
                </ul>
              </p>
              <p>
              Inhaltlich Verantwortlicher gemäß § 55 Abs. 2 RStV:Christian Schmitt
                (nihm das ins Impressum)
              </p>
              </div>
            </div>
          </Branch>
          </BranchDirectRow>

         
       
      </BranchDirectColumn>
      </div>
     
              </>
              );
           }
}