import React, { Component } from 'react';


export default class CardContentElit extends Component {
  constructor(props) {    
    super(props)
    this.state = {

      };

}


  
    render() { 
        return (
                <ul>
                    <li>
                        <h3>Inner Chapels</h3>
                        With pledging allegiance to one of the Sanctuary inner Chapel you gain additional income to fit your needs.
                    </li>
                    <li> 
                        <h3>Ironlight / Crusader / Inquisitor</h3> 
                        As Elite Soldier you deserve the best gear therefore we support you with extra funds.<br/>
                        <h4>Silver: 30k - 5m</h4>
                    </li>
                    <li>
                        <h3>Chapel Captain</h3>
                        Train and lead a squadron consisting out of four Ironlights that belong to your Chapel<br/>
                        <h4>Silver: 5m - 10m</h4>
                    </li>
                </ul>
                );
             }
}