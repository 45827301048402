  document.addEventListener("DOMContentLoaded", ()=>{
    document.querySelector('.root').addEventListener('scroll', (evt) => {
      const vein = document.getElementsByClassName('vein');
      const windowHeight = window.innerHeight;
      Object.keys(vein).map(function(key, index) {
        const positionFromTop = vein[key].getBoundingClientRect().top;
        const positionFromBottom = vein[key].getBoundingClientRect().bottom;
        if (positionFromTop - windowHeight <= -300 && positionFromBottom - windowHeight > -500 ){
            vein[key].classList.remove('animated');
        } 
        else {
            vein[key].classList.add('animated');  
        }
      });
    }, { capture: false, passive: true})
});