import React, { Component } from 'react';
import '../App.scss';
import Branch from '../components/Branch';
import BranchDirectRow from '../components/BranchDirectRow';
import BranchDirectColumn from '../components/BranchDirectColumn';
import Leaf from '../components/Leaf';
import Card from '../components/Card';

//Images
import ironlight from '../assets/img/rankIronlight.jpg';
import chapleLight from '../assets/img/rankChapleLight.jpg';
import chapleCaptain from '../assets/img/rankChapleCaptain.jpg'

//Video


export default class InnerAffairs extends Component {
 
  render() { 
      return (
              <>
      <BranchDirectColumn>
      <BranchDirectRow>
              <Branch>
              <div className="leafnopad">
                <div className="introduction">
                <h1>
                  Ranks of Light
                
                </h1>
                <p>
                To cleanse and repel the Darkness the Ironlight organized in three Ranks to ensure continuous purification of all Dark Spawns.
                </p>
                </div>
                </div>
              </Branch>
          </BranchDirectRow>
          <BranchDirectRow>
          <Branch>  
            <Leaf>
              <Card
                mainClass='vein animated'
                cardImg={ironlight}
                cardVid=''
                headline="Ironlight"
                subline="Soldiers of Light"
                content="A Soldier of the Light faces great danger on his journey through the Serene Badlands to reach the Sanctuary. Those who survive the way of Steel and Faith can become more than a Hero and repel the Darkness."
                 button='View'
                  btnActive='disabled'
              />  
            </Leaf> 
            <Leaf>
            <Card
                mainClass='vein animated'
                cardImg={chapleLight}
                cardVid=''
                headline="Chaple Light"
                subline="Elites of Light"
                content="These elites of the Ironlight execute their duties corresponding to the Chaple and are experts in their respective fields of cleansing the Darkness. Then the Light never yields and therefore those brave Souls spread the Light."
                 button='View'
                  btnActive='disabled'
              />  
            </Leaf> 
            <Leaf>
              <Card
                mainClass='vein animated'
                cardImg={chapleCaptain}
                cardVid=''
                headline="Chaple Captain"
                subline="Keepers of Light"
                content="Every Chaple Captain has his way of leading his Squadron of four Chaple Lights and up to ten Ironlights. Depending on the affiliation of the Chaple Caption the duties bound to the Chaple but the execution can deviate from Captain to Captain."
                 button='View'
                  btnActive='disabled'
              />   
            </Leaf> 
          </Branch> 
          </BranchDirectRow>
      </BranchDirectColumn>
              </>
              );
           }
}