import React, { Component } from 'react';
import '../App.scss';
import scrollFx from '../functions/scrollFx'

export default class Root extends Component {

  componentDidMount() {
    const script = document.createElement("script");
    script.src = {scrollFx};
    script.async = true;
    document.body.appendChild(script);
  }

    render() { 
        return (
                <>
                  <div className="root">
                  {this.props.children}  
                  </div>
                </>
                );
             }
}