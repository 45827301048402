import React, { Component } from 'react';
import '../App.scss';
import Card from '../components/Card';
import Branch from '../components/Branch';
import BranchDirectRow from '../components/BranchDirectRow';
import BranchDirectColumn from '../components/BranchDirectColumn';
import Leaf from '../components/Leaf';
import CardContentReg from '../components/CardContentReg';
import parallax from '../functions/parallax'

//Images

import gate from '../assets/img/gate.jpg';
import rebuilding from '../assets/img/bgr_2.jpg';
import history from '../assets/img/bgr_4.jpg';
import story from '../assets/img/bgr_5.jpg';

import Sanctuary from '../assets/img/sanctuary.jpg';
import Tournament from '../assets/img/tournament.jpg';
import Ranks from '../assets/img/ranks.jpg';
import CardContentElit from '../components/CardContentElit';
import CardContentAdmin from '../components/CardContentAdmin';


export default class Ironlight extends Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.src = {parallax};
    script.async = true;
    document.body.appendChild(script);
  }

  useEffect = () => {
    const script = document.createElement("script");
    script.src = {parallax};
    script.async = true;
    document.body.appendChild(script);
  };
  componentDidUpdate = () => {
    const script = document.createElement("script");
    script.src = {parallax};
    script.async = true;
    document.body.appendChild(script);
  };

  render() { 
      return (
        <>
        <BranchDirectColumn>
            <BranchDirectRow>
              <Branch>
              <div className="leafnopad">
                <div className="introduction">
                  <h1>
                    The Ironlight
                  </h1>
                  <p>
                  The Ironlight is a Black Desert Online Guild and combines the roleplay mechanics of Pen & Paper with the new possibilities of modern Games. To elevate the roleplay part of the Massively Multiplayer Online Role-Playing Game, web design, web development, and marketing is utilized.
                  </p>
                </div>
              </div>
              </Branch>
            </BranchDirectRow>

            <BranchDirectRow>
            <Branch>
            <div className="leafnopad  parallax bg0"></div>
            </Branch>
            </BranchDirectRow>

            <BranchDirectRow>
              <Branch>  
              <Leaf>
              <Card
                mainClass='vein animated'
                cardImg={story}
                cardVid=''
                headline="The Ironlight"
                subline="Steel, Faith, Honor"
                content="We, the Ironlight, are committed to preserving the Light through Steel, Faith, and Honor. The old heroes were corrupted by the Dark Whispers of evil, therefore we encourage our members to ascend beyond. A Kingdom is never built on slavery and oppression. Loyalty emits from the Heart and wellbeing of every Hero."
                button='View'
                btnActive='disabled'
              />  
              </Leaf> 
              <Leaf>
              <Card
                mainClass='vein animated'
                cardImg={rebuilding}
                cardVid=''
                headline="History"
                subline="The Darkness unveiled"
                content="Three days of Darkness were brought down by the Dark Forces and with them, the Sanctuaries were shaken with turmoil and despair. The old commanders of the Sanctuaries are either dead or have become Corruption itself. Brave souls risked their lives in order to bring back what the Dark Forces could not hide behind deadly shadows. The blazing Light, an artifact that can be pushed through the head of a needle, is the last remnant after the lightless days. Those who keep and guarded the Light within their Soul stand tall above the shattered Empire. 
                          They have seen the Light and opened their eyes to it. Bright it shines for those who understand what must be purged."
                button='View'
                btnActive='disabled'
              />  
              </Leaf> 
              <Leaf>
              <Card
                mainClass='vein animated'
                cardImg={history}
                cardVid=''
                headline="Chronicle"
                subline="Chapter I: Rebuilding from Dust to Glory"
                content="The Serene Badlands for those who have forgotten the old tongue. A dreadful place indeed but never then less hope still resides in this region. The Ironlight is regaining its numbers. Following the Light and the corresponding souls. Let me tell you... while I guide you to the Sanctuary... not all souls can be saved... some are committed to absolute darkness. Anyway, the Badlands won't be bad for long. So then fellow Ironlight tell me your story..."
                button='Tell your story'
                btnActive='tgb-btn'
                link="https://serene-badlands-47207.herokuapp.com/"
                linkTarget="_blank"
              />    
              </Leaf> 
  
            </Branch> 
            </BranchDirectRow>

            <BranchDirectRow>
            <Branch>
            <div className="leafnopad parallax bg1"></div>
            </Branch>
            </BranchDirectRow>

            <BranchDirectRow>
              <Branch>  

              <Leaf>
              <Card
                mainClass='vein animated'
                cardImg={Sanctuary}
                cardVid=''
                headline="Sanctuary"
                subline="The Chapels"
                content="The Sanctuary consists out of three Chaples with their distinct duties to extend the influence of the Sanctuary."
                btnActive='vein-btn'
                button='More'
                link="ironlight/sanctuary"
                linkTarget="_blank"
              />  
            </Leaf> 
            <Leaf>
            <Card
                mainClass='vein animated'
                cardImg={Tournament}
                cardVid=''
                headline="Tournaments"
                subline="For Glory"
                content="To help our members to ascend beyond being just a Hero, the Ironlights organizes three Tournaments for every quarter of the year."
                btnActive='vein-btn'
                button='More'
                link="ironlight/tournaments"
                linkTarget="_blank"
              />  
            </Leaf> 
            <Leaf>
              <Card
                mainClass='vein animated'
                cardImg={Ranks}
                cardVid=''
                headline="Inner Affairs"
                subline="Ranks of Light"
                content="To cleanse and repel the Darkness the Ironlight organized in three Ranks to ensure continuous purification of all Dark Spawns."
                btnActive='vein-btn'
                button='More'
                link="ironlight/inneraffairs"
                linkTarget="_blank"
              />   
            </Leaf> 
            </Branch> 
            </BranchDirectRow>

            <BranchDirectRow>
            <Branch>
              <div className="leafnopad bg3 parallax"></div>
            </Branch>
            </BranchDirectRow>

            <BranchDirectRow>
              <Branch>  
              <Leaf>
              <Card
                  mainClass='vein animated textcenter'
                  cardImg=''
                  cardVid=''
                  cardVidDisabled='disabled'
                  headline="Help to rebuild the Kingdom"
                  subline="Tell your story of becoming more than a Hero."
                  content="Enlist to the Ironlight and start rebuilding now."
                  button='Join Discord'
                  btnActive='vein-btn'
                  link="https://discord.gg/qsfCQgt"
                  linkTarget="_blank"
                />     
              </Leaf> 
            
            </Branch> 
            </BranchDirectRow>
        </BranchDirectColumn>
                </>
              );
           }
}