import React, { Component } from 'react';
import '../App.scss';
import Branch from '../components/Branch';
import BranchDirectRow from '../components/BranchDirectRow';
import BranchDirectColumn from '../components/BranchDirectColumn';
import Leaf from '../components/Leaf';
import Card from '../components/Card';

//Images
import battleOfIron from '../assets/img/KingofIron.jpg';
import clashOfLight from '../assets/img/PurificationSquadron.jpg';
import relentlessGuard from '../assets/img/RelentlessGuard.jpg'

//Video


export default class Tournaments extends Component {
 
  render() { 
      return (
              <>
      <BranchDirectColumn>
      <BranchDirectRow>
              <Branch>
              <div className="leafnopad">
                <div className="introduction">
                <h1>
                Tournaments
                
                </h1>
                <p>
                To help our members to ascend beyond being just a Hero, the Ironlights organizes three Tournaments for every quarter of the year.<br/>
                The victories Contesten of the Tournament earns a title and is given a Badge of Glory for the quarter.<br/><br/>
                As long as the Victor is undefeated he keeps the badge and title throughout the year.<br/>
                Every badge raises the weekly Guild Bonus to acknowledge the achieved Glory.<br/><br/>
                Rules for every Tournament<br/>
                Forbidden: Pots / Food / Ghillie Suit<br/>
                Allowed: Traps / Bombs / Matchlock
                </p>
                </div>
                </div>
              </Branch>
          </BranchDirectRow>
          <BranchDirectRow>
          <Branch>  
            <Leaf>
              <Card
                mainClass='vein animated'
                cardImg={battleOfIron}
                cardVid=''
                headline="Battle of Iron"
                subline="Hero vs Hero"
                content="Show your Might and prevail in a best of three to earn Glory among your fellow Ironlights and become the King of Iron."
                 button='View'
                  btnActive='disabled'
              />  
            </Leaf> 
            <Leaf>
            <Card
                mainClass='vein animated'
                cardImg={clashOfLight}
                cardVid=''
                headline="Clash of Light"
                subline="Team Battle"
                content="Fight with your fellow Ironlight in a Battle where all your Teammates have to survive to achieve victory. Be the best out of three fights and earn the title Purification Squadron"
                 button='View'
                  btnActive='disabled'
              />  
            </Leaf> 
            <Leaf>
              <Card
                mainClass='vein animated'
                cardImg={relentlessGuard}
                cardVid=''
                headline="Relentless Guard"
                subline="A Fight to the End"
                content="As Relentless Guard you need to defeat four enemies in a row, facing you one by one. Prove you could guard the Artifact of Light to be granted the title of Relentless Guard"
                 button='View'
                  btnActive='disabled'
              />   
            </Leaf> 
          </Branch> 
          </BranchDirectRow>
      </BranchDirectColumn>
              </>
              );
           }
}