import React, { Component } from 'react';


export default class CardContentAdmin extends Component {
  constructor(props) {    
    super(props)
    this.state = {

      };

}


  
    render() { 
        return (
                <ul>
                    <li>
                        <h3>Contracts</h3>
                        Every Contract lasts for 365 days upon renewal. Payment can be increased by turning in activity points. The Chapel for Inner Affairs is responsible for maintaining all Contracts.<br/>
                        <h4>Length: 365 Days</h4>
                    </li>
                    <li> 
                        <h3>Promotion</h3> 
                        The Chapel Clerks promote accordingly to the activity log. Contracts get renewed at Serendia 3<br/>
                        <h4>Main server: Serendia 3</h4>
                    </li>
                    <li>
                        <h3>Extra Content</h3>
                        Discord is required for Sanctuary Payrolls and Ironlight extra Content<br/>
                        <h4>Discord: Required</h4>
                    </li>
                </ul>
                );
             }
}