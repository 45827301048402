import React, { Component } from 'react';
import Branch from '../components/Branch';
import BranchDirectRow from '../components/BranchDirectRow';
import BranchDirectColumn from '../components/BranchDirectColumn';
import Leaf from '../components/Leaf';
import Card from '../components/Card';
import '../mrms/mrmsApp.scss';

//Images
import probeklausur from '../assets/mrms/probeklausur.jpg';
import summer from '../assets/mrms/summer.jpg';
import winter from '../assets/mrms/logomrms.png';
import loesung from '../assets/mrms/loesung.jpg';
import anleitung from '../assets/mrms/anleitung.jpg';
//Data
import dataAnleitung from '../assets/mrms/AnleitungZurProbeklausur.pdf';
import dataProbeklausur from '../assets/mrms/SAGGProbeklausur2019WISE.pdf';
import dataProbeklausurLösung from '../assets/mrms/SAGGProbeklausur2019WISELoesung.pdf';


export default class About extends Component {
  constructor(props) {    
    super(props);
  
    this.state = {

      };
      this.enableDownload = this.enableDownload.bind(this);
     
  }
  enableDownload(){
  
       const Content = document.getElementsByClassName('mrmsContent');
       const fullH = document.getElementsByClassName('fullH');
       const Box = document.getElementById('c1');
       const akzept = document.getElementById('c1a');
       console.log(Box.checked)
       if(Box.checked === true){

         Content[0].classList.add('enabled');  
         fullH[0].classList.add('disabled'); 
       }
       akzept.classList.remove('disabled');
       console.log('Bitte akzeptieren Sie die AGB')
      };
  render() { 
    require('../mrms/mrmsApp.scss')
    require('../functions/disable')
      return (
              <>
  <div className="mrms"> 
      <BranchDirectColumn>  
          <BranchDirectRow>   
          <Branch>
            <div className="mrms-logo-container">
            <div className="mrms-logo">
              <img src={winter} alt="logo"></img>
            </div>
            </div>
            <div className="leafnopad">
            <div className="introduction">
              <h1>
              MrMatheschmitt
              </h1>
              <p>
              Mein Name ist Christian Schmitt und ich verstehe mich leidenschaftlich darauf komplizierte Sachverhalte in der Mathematik einfach und verständlich für Normalsterbliche zu vermitteln.<br/><br/> <h3>Meine Angebote:</h3> 
              <ul className="list">
                <li>
                Gezielte Stoffvermitlung
                </li>
                <li>
                Lernscripte
                </li>
                <li>
                Online Videos
                </li>
                <li>
                Übungsklausuren
                </li>
                <li>
                Psychologische Beratung bei Prüfungsangst
                </li>
                <li>
                Studienplannung und Lernplanung
                </li>
              </ul>
              </p>
              </div>
            </div>
            <div className="leafnopad">
            <div className="introduction">
                <h1>
                  Social Networks 
                </h1>
                <p>
                <a href="https://www.facebook.com/MisterMatheSchmitt" target="_blank" className="tgb-btn">Facebook</a>
                <a href="https://www.youtube.com/channel/UCSbmQHofacdz4QSD4j1u4Tw" target="_blank" className="tgb-btn">YouTube</a>
                <a href="https://discord.gg/XfxgYEJ" target="_blank" className="tgb-btn">Discord</a>
                </p>
                </div>
            </div>
          </Branch>
          </BranchDirectRow>
          <BranchDirectRow>   
            <Branch>
            <div className="fullH">
            <div className="leafnopad">
            <div className="introduction">
              <h1>
              AGB
              </h1>
              <p>
                Bitte lesen Sie die <a href="/MrMatheSchmitt/agb" className="activ-link" target="_blank">AGB</a> und akzeptieren diese, um zu den Downloads zu gelangen.
              </p>
             
              <div className="AGB">
              <input type="checkbox" id="c1" name="c1" />
              <label htmlFor="c1"><span></span>Ich habe die AGB gelesen und verstanden.</label>
              <button onClick={() => {this.enableDownload()}} className="tgb-btn">AGB akzeptieren</button>
              <p id="c1a" className="disabled">Bitte akzeptieren Sie the AGB.</p>
              </div>
              
              </div>
            </div>
            </div>
            </Branch>
          </BranchDirectRow>   
          <div className="mrmsContent">
          <BranchDirectRow>
            <Branch>
            <Leaf>
              <Card
                mainClass='vein'
                cardImg={anleitung}
                cardVid=''
                headline="Anleitung Probeklausur"
                subline="Tipps & Tricks"
                content="Sinn und Zweck ist es die Klausursituation mit allen Schickanen an sich so
                nah wie möglich zu simulieren."
                button='Download'
                btnActive='tgb-btn'
                link={dataAnleitung}
                linkTarget="_blank"
              />  
            </Leaf> 
            <Leaf>
              <Card
                mainClass='vein animated'
                cardImg={probeklausur}
                cardVid=''
                headline="Aktuelle Probeklausur"
                subline="SS 2020"
                content="Sechs Übungsaufgaben zur Vorbereitung zur Klausur."
                button='Download'
                btnActive='tgb-btn'
                link={dataProbeklausur}
                linkTarget="_blank"
              />  
            </Leaf> 
            <Leaf>
              <Card
                mainClass='vein animated'
                cardVid=''
                cardImg={loesung}
                headline="Lösung"
                subline="Probeklausur SS 2020"
                content="Alle Lösungen zur Probeklausur."
                button='Download'
                btnActive='tgb-btn'
                link={dataProbeklausurLösung}
                linkTarget="_blank"
                
              />   
            </Leaf> 
          </Branch> 
          </BranchDirectRow>
          </div>
         
          <BranchDirectRow>   
          <Branch>
          <a href="/MrMatheSchmitt/impressum" target="_blank" className="tgb-btn">
         
            Impressum

            </a>
            <a href="/MrMatheSchmitt/agb" target="_blank" className="tgb-btn">
         
         AGB

         </a>


          </Branch>
          </BranchDirectRow>   
      </BranchDirectColumn>
      </div>
     
              </>
              );
           }
}